<template>
  <div class="login">
    <div class="container">
      <div class="row">
        <div class="col-md-4">

        </div>
        <div class="col-md-4 login-form">
          <h2 class="login-form__h2">Welcome to CMS</h2>
          <p class="login-form__p">Have an account?</p>

          <form @submit.prevent="submit">
              <el-input
                v-model="userData.email"
                required
                placeholder="Email*"
                v-on:keyup.enter="submit"
              ></el-input>
              <el-input
                v-model="userData.password"
                required
                placeholder="password*"
                type="password"
                v-on:keyup.enter="submit"
              ></el-input>
              <div class="login-form__buttons">
                <el-button
                  class="mr-4 login-form__submit-button"
                  @click="submit"
                >
                  Login
                </el-button>
              </div>
          </form>
          <div class="login-form__footer">
            <div class="forgot_password">
<!--              <a href="#" class="forgot_password__a">Forgot password?</a>-->
            </div>
          </div>

        </div>
        <div class="col-md-4">

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import api from '@/services/api'

export default {
  name: 'Login',
  data: () => ({
    userData: {
      email: '',
      password: ''
    }
  }),

  methods: {
    submit () {
      const loader = this.$loading.show()
      api.post('api/auth/login', {
        email: this.userData.email,
        password: this.userData.password
      }).then((results) => {
        this.$router.push('/')
        this.$store.dispatch('setUserObject', results.data)
        var authToken = results.data.access_token
        var userName = results.data.user_name
        this.$cookies.set('bearerToken', authToken)
        this.$cookies.set('userName', userName)
        loader.hide()
      }).catch((error) => {
        if (error.request.status === 401) {
          this.$notify.error({
            title: 'Error',
            message: 'Please check you credentials!',
            type: 'error'
          })
        }
        loader.hide()
      })
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.login {
  height: 100vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/img/login-background-image.jpg");
}

.login-form {
  max-width: 450px;
  margin: 0 auto;
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
}

.login-form__h2 {
  color: white;
  font-size: 40px;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 500;
}

.login-form__p {
  margin-bottom: 30px;
  font-size: 22px;
  color: white;
  text-align: center;
}

.login-form__buttons {
  margin-top: 30px;
  padding: 15px 30px;
}

.login-form__buttons .login-form__submit-button {
  background-color: #42b983;
  border: 2px solid #42b983;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.4s ease;
}

.login-form__buttons .login-form__submit-button:hover {
  color: #42b983;
  border-color: #42b983;
  background-color: transparent;
}

.login-form__submit-button {
  width: 100%;
  border-radius: 40px;
}

.login-form__footer {
  margin-top: 30px;
}

.login-form .el-input {
  margin-bottom: 15px;
}

.forgot_password__a {
  color: white;
  float: right;
  text-decoration: none;
}

</style>
