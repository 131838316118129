import api from '../../services/api'

export default {
  state: {
    fakeNews: null,
    paginationLinks: null,
    currentPage: null
  },
  mutations: {
    SET_ITEMS_DATA (state, data) {
      state.fakeNews = data
    },
    SET_PAGINATION_LINKS (state, data) {
      state.paginationLinks = data
      state.currentPage = data.current_page
    },
    DELETE_ITEM_ITEMS (state, id) {
      state.items.map((singleState, index) => {
        if (singleState.id === id) {
          state.items.splice(index, 1)
        } else if (singleState.post_id === id) {
          state.items.splice(index, 1)
        }
      })
    }
  },
  actions: {
    fetchFakeNews ({ commit }, obj) {
      if (obj.date) {
        var startDate = obj.date[0]
        var endDate = obj.date[1]
      }
      return api.get('api/' + obj.type + '?per_page=50&page=' + obj.page + '&partner=' + obj.partner + '&start_date=' + startDate + '&end_date=' + endDate + '&filter_compound=' + obj.filter_compound).then((response) => {
        commit('SET_PAGINATION_LINKS', response.data)
        commit('SET_ITEMS_DATA', response.data.data)
      })
    }
  }
}
