import api from '../../services/api'

export default {
  state: {
    items: null,
    orderBy: null,
    orderDirection: null,
    paginationLinks: null,
    currentPage: null
  },
  mutations: {
    SET_ITEMS_DATA (state, data) {
      state.items = data
    },
    SET_PAGINATION_LINKS (state, data) {
      state.paginationLinks = data
      state.currentPage = data.current_page
    },
    DELETE_ITEM_ITEMS (state, id) {
      state.items.map((singleState, index) => {
        if (singleState.id === id) {
          state.items.splice(index, 1)
        } else if (singleState.post_id === id) {
          state.items.splice(index, 1)
        }
      })
    },
    setOrderBy (state, orderBy) {
      state.orderBy = orderBy
    },
    setOrderDirection (state, orderDirection) {
      state.orderDirection = orderDirection
    }
  },
  actions: {
    fetchData ({ commit }, obj) {
      if (obj.date) {
        var startDate = obj.date[0]
        var endDate = obj.date[1]
      }
      return api.get('api/' + obj.type + '?partner=' + obj.partner + '&start_date=' + startDate + '&end_date=' + endDate + '&per_page=50&page=' + obj.page + '&sort_views=' + obj.sort_views + '&sort_transmission=' + obj.sort_transmission).then((response) => {
        commit('SET_PAGINATION_LINKS', response.data)
        commit('SET_ITEMS_DATA', response.data.data)
      })
    },
    deleteItem ({ commit }, obj) {
      return api.delete('api/' + obj.type + '/' + obj.id).then((response) => {
        commit('DELETE_ITEM_ITEMS', obj.id)
      })
    },
    restoreItem ({ commit }, obj) {
      return api.post('api/' + obj.type + '/trash/' + obj.id).then((response) => {
        commit('DELETE_ITEM_ITEMS', obj.id)
      })
    },
    permanentDelete ({ commit }, obj) {
      return api.delete('api/' + obj.type + '/trash/' + obj.id).then((response) => {
        commit('DELETE_ITEM_ITEMS', obj.id)
      })
    }
  }
}
