export function postsObject () {
  const postsObj = {
    menuName: 'Preneti vesti',
    title: {
      name: 'Naslov vesti',
      show: true,
      columns: '4'
    },
    categories: {
      name: 'Categories',
      show: false,
      columns: '3'
    },
    source: {
      name: 'Autor',
      show: true,
      columns: 2
    },
    created_at: {
      name: 'Objavljeno',
      show: true,
      columns: '1'
    },
    views: {
      name: 'Br. pregleda',
      show: true,
      orderBy: 'views',
      columns: '1'
    },
    languages: {
      name: 'Languages',
      show: false,
      columns: '3'
    },
    transited_news: {
      name: 'Preneli',
      show: true,
      columns: '2'
    },
    transited_count: {
      name: 'Br. prenosa',
      show: true,
      columns: 1,
      orderBy: 'transmited_number'
    }
  }
  return postsObj
}

export function pagesObject () {
  const pagesObj = {
    menuName: 'Pages',
    title: {
      name: 'Title',
      show: true,
      columns: '5'
    },
    categories: {
      name: 'Categories',
      show: false,
      columns: '3'
    },
    author: {
      name: 'Author',
      show: true,
      columns: 3
    },
    created_at: {
      name: 'Created at',
      show: true,
      columns: '2'
    },
    status: {
      name: 'Status',
      show: true,
      columns: '2'
    },
    languages: {
      name: 'Languages',
      show: false,
      columns: '3'
    },
    deleted_at: {
      name: 'Deleted at',
      show: false,
      columns: '2'
    }
  }
  return pagesObj
}

export function categoriesObject () {
  const categoriesObj = {
    menuName: 'Categories',
    title: {
      name: 'Title',
      show: true,
      columns: '6'
    },
    categories: {
      name: 'Categories',
      show: false,
      columns: '3'
    },
    author: {
      name: 'Author',
      show: false,
      columns: 2
    },
    created_at: {
      name: 'Created at',
      show: true,
      columns: '6'
    },
    status: {
      name: 'Status',
      show: false,
      columns: '2'
    },
    languages: {
      name: 'Languages',
      show: false,
      columns: '3'
    },
    deleted_at: {
      name: 'Deleted at',
      show: false,
      columns: '2'
    }
  }
  return categoriesObj
}

export function pagesTrashObject () {
  const pagesTrashObj = {
    menuName: 'Pages trash',
    title: {
      name: 'Title',
      show: true,
      columns: '6'
    },
    categories: {
      name: 'Categories',
      show: false,
      columns: '3'
    },
    author: {
      name: 'Author',
      show: false,
      columns: 2
    },
    created_at: {
      name: 'Created at',
      show: false,
      columns: '1'
    },
    status: {
      name: 'Status',
      show: false,
      columns: '2'
    },
    languages: {
      name: 'Languages',
      show: false,
      columns: '3'
    },
    deleted_at: {
      name: 'Deleted at',
      show: true,
      columns: '6'
    }
  }

  return pagesTrashObj
}

export function postsTrashObject () {
  const postsTrashObj = {
    menuName: 'Posts trash',
    title: {
      name: 'Title',
      show: true,
      columns: '6'
    },
    categories: {
      name: 'Categories',
      show: false,
      columns: '3'
    },
    author: {
      name: 'Author',
      show: false,
      columns: 2
    },
    created_at: {
      name: 'Created at',
      show: false,
      columns: '1'
    },
    status: {
      name: 'Status',
      show: false,
      columns: '2'
    },
    languages: {
      name: 'Languages',
      show: false,
      columns: '3'
    },
    deleted_at: {
      name: 'Deleted at',
      show: true,
      columns: '6'
    }
  }

  return postsTrashObj
}

export function fakeNewsObject () {
  const fakeNewsObject = {
    menuName: 'Preneti vesti',
    title: {
      name: 'Naslov vesti',
      show: true,
      columns: '4'
    },
    categories: {
      name: 'Categories',
      show: false,
      columns: '3'
    },
    source: {
      name: 'Autor',
      show: true,
      columns: 2
    },
    created_at: {
      name: 'Objavljeno',
      show: true,
      columns: '1'
    },
    views: {
      name: 'Br. pregleda',
      show: false,
      orderBy: 'views',
      columns: '1'
    },
    languages: {
      name: 'Languages',
      show: false,
      columns: '3'
    },
    emotional_score: {
      name: 'Emocionalni skor',
      show: true,
      columns: '2',
      orderBy: 'emotional_score_sort'
    },
    transited_count: {
      name: 'Br. prenosa',
      show: false,
      columns: 1
    }
  }

  return fakeNewsObject
}
