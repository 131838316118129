<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">
      <p>{{ currentType }} {{ $route.params.type }}</p>
    </div>
  </div>
  <div class="single-post">
    <div>
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-md-8 single_post_main_box">
            <el-input
              v-model="itemData.title"
              required
              class="add-new-item__title"
              placeholder="Title"
            ></el-input>
            <tiptap v-model="itemData.content"/>
          </div>
          <div class="col-md-4 single_post_sidebar">
            <div class="row">
              <div class="col-md-12">
                <div class="buttons_section single_post_box">
                  <el-button
                    class="button_style_1"
                    v-if="currentType === 'new'"
                    @click="addNew"
                  >
                    Publish
                  </el-button>
                  <el-button
                    v-else-if="currentType === 'edit'"
                    class="button_style_1"
                    @click="edit"
                  >
                    Save
                  </el-button>
                </div>
              </div>
              <div class="col-xl-12" v-if="$route.params.type !== 'category'">
                <div class="post_status single_post_box">
                  <h3 class="single_post_box__h3">Status</h3>
                  <el-select v-model="itemData.status" placeholder="Select">
                    <el-option
                      v-for="item in itemData.statuses"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="post_categories single_post_box" v-if="categories.length != 0">
                  <h3 class="single_post_box__h3">Categories</h3>
                  <div class="categories_box">
                    <div v-for="(category, index) in categories" :key="index"
                         class="categories_box__single_category">
                      <div v-for="(singleCat, index2) in category.category_content" :key="index2"
                           class="single_category__div">
                        <input
                          type="checkbox"
                          name="categories"
                          :id="`category-`+singleCat.id"
                          v-model="itemData.categories"
                          :value="singleCat.id"
                          :label="singleCat.name"
                          class="category_checkbox"
                        >
                        <label :for="`category-`+singleCat.id">
                          {{ singleCat.name }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-12">
                <div class="single_post_box">
                  <h3 class="single_post_box__h3">Featured image</h3>
                  <ImageSelectorInput
                    v-model="itemData.featuredImage"
                    :multiple="false"
                    :featuredImageSlug="itemData.featuredImageSlug"
                    v-if="itemData.featuredImageSlug"></ImageSelectorInput>
                </div>
              </div>
              <div class="col-xl-12" v-if="$route.params.type === 'post'">
                <div class="single_post_box">
                  <h3 class="single_post_box__h3">Gallery</h3>
                  <ImageSelectorInput
                    v-model="itemData.gallery"
                    :multiple="true"
                    :featuredImageSlug="itemData.featuredImageSlug"
                    :gallery="gallery"
                    v-if="itemData.featuredImageSlug"></ImageSelectorInput>
                </div>
              </div>
              <div class="col-md-12" v-if="$route.params.type === 'post'">
                <div class="single_post_box">
                  <h3 class="single_post_box__h3">Audio file</h3>
                  <AudioSelectorInput
                    v-model="itemData.audioFile"
                    :multiple="false"
                    :featuredAudioSlug="itemData.featuredAudioSlug"
                    v-if="itemData.featuredAudioSlug">
                  </AudioSelectorInput>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import Tiptap from '../../components/Tiptap/Tiptap'
import ImageSelectorInput from '../../components/Tiptap/ImageSelectorInput'
import AudioSelectorInput from '../../components/Tiptap/AudioSelectorInput'
import api from '../../services/api'
import { getSinglePage, getSinglePost } from '../../services/items'
import { getAllCategories, getSingleCategory } from '../../services/category'

export default {
  name: 'NewItem',
  components: {
    Tiptap,
    ImageSelectorInput,
    AudioSelectorInput
  },
  props: {
    type: {
      type: String,
      default: 'post'
    }
  },
  data () {
    return {
      currentType: null,
      pageObject: {},
      lang: 'en',
      gallery: [],
      selectedImage: null,
      categories: [],
      removeImageFromGallery: null,
      newItemType: null,
      content: null,
      itemData: {
        title: '',
        author: '',
        created_date: '',
        featuredAudioSlug: '',
        audioFile: '',
        content: '',
        gallery: [],
        categories: [],
        featuredImageSlug: '',
        featuredImage: '',
        status: '',
        statuses: [
          {
            id: 2,
            name: 'Publish'
          },
          {
            id: 1,
            name: 'Draft'
          }
        ]
      }
    }
  },
  methods: {
    addNew () {
      const loader = this.$loading.show()
      if (this.$route.params.type === 'post') {
        api.post('api/posts', {
          lang: 'en',
          title: this.itemData.title,
          post_content: this.itemData.content,
          featured_image_id: this.itemData.featuredImage,
          categories: this.itemData.categories,
          gallery: this.itemData.gallery,
          status_id: this.itemData.status,
          audio_id: this.itemData.audioFile
        }).then(() => {
          loader.hide()
          this.$notify({
            title: 'Success',
            message: 'Post is successfully added.',
            type: 'success'
          })
        }).catch((error) => {
          loader.hide()
          const objError = JSON.parse(error.request.response)
          let errorMessage
          if (objError.errors.title !== undefined) {
            errorMessage = 'Title is already taken!'
          } else if (objError.errors.status_id !== undefined) {
            errorMessage = 'Status is required field!'
          } else {
            errorMessage = 'Please fill all required fields!'
          }
          this.$notify.error({
            title: 'Error',
            message: errorMessage,
            type: 'error'
          })
        })
      } else if (this.$route.params.type === 'page') {
        api.post('api/pages', {
          lang: 'en',
          title: this.itemData.title,
          body_content: this.itemData.content,
          featured_image_id: this.itemData.featuredImage,
          status_id: this.itemData.status
        }).then(() => {
          loader.hide()
          this.$notify({
            title: 'Success',
            message: 'Page is successfully added.',
            type: 'success'
          })
        }).catch((error) => {
          loader.hide()
          const objError = JSON.parse(error.request.response)
          let errorMessage
          if (objError.errors.title !== undefined) {
            errorMessage = objError.errors.title[0]
          } else if (objError.errors.status_id !== undefined) {
            errorMessage = 'The status field is required!'
          } else {
            errorMessage = 'Please fill all required fields!'
          }
          this.$notify.error({
            title: 'Error',
            message: errorMessage,
            type: 'error'
          })
        })
      } else if (this.$route.params.type === 'category') {
        api.post('api/category', {
          lang: 'en',
          name: this.itemData.title,
          description: this.itemData.content
        }).then(() => {
          loader.hide()
          this.$notify({
            title: 'Success',
            message: 'Category is successfully added.',
            type: 'success'
          })
        }).catch((error) => {
          loader.hide()
          const objError = JSON.parse(error.request.response)
          let errorMessage
          if (objError.errors.title !== undefined) {
            errorMessage = 'Title is already taken!'
          } else if (objError.errors.status_id !== undefined) {
            errorMessage = 'Status is required field!'
          } else {
            errorMessage = 'Please fill all required fields!'
          }
          this.$notify.error({
            title: 'Error',
            message: errorMessage,
            type: 'error'
          })
        })
      }
    },
    edit () {
      const loader = this.$loading.show()
      if (this.$route.params.type === 'post') {
        api.put('api/posts/' + this.$route.params.id, {
          lang: 'en',
          title: this.itemData.title,
          post_content: this.itemData.content,
          categories: this.itemData.categories,
          gallery: this.itemData.gallery,
          featured_image_id: this.itemData.featuredImage,
          status_id: this.itemData.status,
          audio_id: this.itemData.audioFile
        }).then(() => {
          loader.hide()
          this.$notify({
            title: 'Success',
            message: 'Post is successfully edited.',
            type: 'success'
          })
        }).catch((error) => {
          loader.hide()
          const objError = JSON.parse(error.request.response)
          let errorMessage
          if (objError.errors.title !== undefined) {
            errorMessage = objError.errors.title[0]
          } else {
            errorMessage = 'Server error! Please try later!'
          }
          this.$notify.error({
            title: 'Error',
            message: errorMessage,
            type: 'error'
          })
        })
      } else if (this.$route.params.type === 'page') {
        api.put('api/pages/' + this.$route.params.id, {
          lang: 'en',
          title: this.itemData.title,
          body_content: this.itemData.content,
          featured_image_id: this.itemData.featuredImage,
          status_id: this.itemData.status
        }).then(() => {
          loader.hide()
          this.$notify({
            title: 'Success',
            message: 'Page is successfully edited.',
            type: 'success'
          })
        }).catch((error) => {
          loader.hide()
          const objError = JSON.parse(error.request.response)
          let errorMessage
          if (objError.errors.title !== undefined) {
            errorMessage = objError.errors.title[0]
          } else {
            errorMessage = 'Server error! Please try later!'
          }
          this.$notify.error({
            title: 'Error',
            message: errorMessage,
            type: 'error'
          })
        })
      } else if (this.$route.params.type === 'category') {
        api.put('api/category/' + this.$route.params.id, {
          lang: 'en',
          name: this.itemData.title,
          description: this.itemData.content
        }).then((results) => {
          this.$notify({
            title: 'Success',
            message: 'Category is successfully edited.',
            type: 'success'
          })
          loader.hide()
        }).catch((error) => {
          const objError = JSON.parse(error.request.response)
          let errorMessage
          if (objError.errors.name !== undefined) {
            errorMessage = objError.errors.name[0]
          } else {
            errorMessage = 'Server error! Please try later!'
          }
          this.$notify.error({
            title: 'Error',
            message: errorMessage,
            type: 'error'
          })
          loader.hide()
        })
      }
    }
  },
  mounted () {
    this.currentType = 'new'
    if (this.$route.params.type === 'post') {
      const loader = this.$loading.show()
      getAllCategories().then(results => {
        this.categories = results
        loader.hide()
      })
    }
    if (this.$route.params.id) {
      if (this.$route.params.type === 'post') {
        this.currentType = 'edit'
        const loader = this.$loading.show()
        getSinglePost(this.$route.params.id).then(post => {
          post.categories.map(singleCat => {
            this.itemData.categories.push(singleCat.id)
          })
          post.gallery.map(singleImage => {
            singleImage.src = process.env.VUE_APP_SHARED_API + singleImage.properties.thumbnail
            this.gallery.push(singleImage)
          })
          post.gallery.map(singleImage => {
            this.itemData.gallery.push(singleImage.id)
          })
          if (post.featured_image) {
            this.itemData.featuredImageSlug = process.env.VUE_APP_SHARED_API + post.featured_image.properties.medium
          } else {
            this.itemData.featuredImageSlug = 'empty'
          }
          this.itemData.featuredImage = post.featured_image_id
          this.itemData.author = post.author.name
          post.post_content.map(singlePost => {
            this.itemData.audioFile = singlePost.audio_id
            if (singlePost.audio) {
              this.itemData.featuredAudioSlug = process.env.VUE_APP_SHARED_API + singlePost.audio.slug
            } else {
              this.itemData.featuredAudioSlug = 'empty'
            }
            this.itemData.title = singlePost.title
            this.itemData.content = singlePost.content
            this.itemData.status = singlePost.status.id
            this.itemData.created_date = singlePost.created_at
          })
          loader.hide()
        })
      } else if (this.$route.params.type === 'page') {
        this.currentType = 'edit'
        const loader = this.$loading.show()
        getSinglePage(this.$route.params.id).then(page => {
          if (page.featured_image) {
            this.itemData.featuredImageSlug = process.env.VUE_APP_SHARED_API + page.featured_image.properties.medium
          } else {
            this.itemData.featuredImageSlug = 'empty'
          }
          this.itemData.featuredImage = page.featured_image_id
          this.itemData.author = page.author.name
          page.page_content.map(singlePage => {
            this.itemData.title = singlePage.title
            this.itemData.content = singlePage.content
            this.itemData.status = singlePage.status.id
            this.itemData.created_date = singlePage.created_at
          })
          loader.hide()
        })
      } else {
        this.currentType = 'edit'
        const loader = this.$loading.show()
        getSingleCategory(this.$route.params.id).then(results => {
          this.itemData.title = results.name
          this.itemData.content = results.description
          loader.hide()
        })
      }
    }
    if (this.currentType === 'new') {
      this.itemData.featuredImageSlug = 'empty'
      this.itemData.featuredAudioSlug = 'empty'
    }
  },
  watch: {
    $route (to) {
      if (to.params.type === 'post') {
        const loader = this.$loading.show()
        getAllCategories().then(results => {
          this.categories = results
          loader.hide()
        })
      }
      if (to.params.id) {
        this.currentType = 'edit'
      } else {
        this.currentType = 'new'
        Object.keys(this.itemData).forEach(key => {
          this.itemData[key] = ''
        })
        this.categories = []
      }
      if (this.currentType === 'new') {
        this.itemData.featuredImageSlug = 'empty'
        this.itemData.featuredAudioSlug = 'empty'
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.navigation-title-section-content {
  p {
    margin-bottom: 0;
    text-transform: capitalize;
  }
}

.single_post_main_box {
  padding: 25px;
  background-color: #f2f2f2;
  border-radius: 5px;
}

.single_post_box {
  background-color: #f2f2f2;
  padding: 25px;
  margin-bottom: 25px;
  border-radius: 5px;

  .single_post_box__h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .featured_image_single {
    max-width: 400px;
    margin: 0 auto;
  }
}

.category_checkbox:active, .category_checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.categories_box {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  height: 150px;
  overflow-y: scroll;
}

.single_category__div {
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 15px;

  .category_checkbox {
    -webkit-appearance: none;
    background-color: #fafafa;
    border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    margin-right: 10px;
  }

  label {
    font-size: 14px;
    cursor: pointer;
  }

  .category_checkbox:checked {
    background-color: #e9ecee;
    border: 1px solid #42b983;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
  }

  .category_checkbox:checked:after {
    content: '\2714';
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 3px;
    color: #42b983;
  }
}

.buttons_section {
  text-align: right;
}

</style>
